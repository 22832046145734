<template>
  <footer class="footer">
    <div class="footer__content container">
      <p class="footer__content-info bold-txt">© Škoda 2023</p>
    </div>
  </footer>

  <div class="contacts">
    <div class="contacts__icon">
      <div
          class="contacts__icon_content"
          v-if="clickContactsIcon"
          :class="{ 'slide-in': clickContactsIcon }"
      >
        <a href="tel:9898" class="contacts__icon_content-item">
          <img src="@/assets/img/svg/phone-icon.svg" alt="">
          <p class="bold-txt">9898</p>
        </a>
        <a href="http://wa.me/77710891010?text=Здравствуйте!%20Хочу%20консультацию" class="contacts__icon_content-item">
          <img src="@/assets/img/svg/whatsapp.svg" alt="whatsapp">
          <p class="bold-txt">+7 771 089 10 10</p>
        </a>
        <div class="contacts__icon_content-item">
          <p class="bold-txt" @click="scrollToApp">{{ $t('buttons.submitApp') }}</p>
        </div>
      </div>

      <picture
          class="contacts__icon_phone"
          @click="showContact"
      >
        <img src="@/assets/img/svg/phone-icon.svg" alt="contact">
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  name:'FooterVue',
  data() {
    return {
      clickContactsIcon: false,
    };
  },
  methods: {
    showContact() {
      return this.clickContactsIcon = !this.clickContactsIcon,
          this.openMarsh = false
    },
    scrollToApp() {
      const submitAppBlock = document.querySelector('.home__submitApp');
      if (submitAppBlock) {
        submitAppBlock.scrollIntoView({ behavior: 'smooth' });
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  background-color: $quaternary;
  height: 60px;
  width: 100%;
  color: $white;
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;
  color: $white;
  width: 100%;
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 999;
  @media screen and (max-width: 1200px) {
    bottom: 135px;
  }
  @media screen and (max-width: $tablet) {
    right: 30px;
  }
  @media screen and (max-width: $mobile) {
    right: 10px;
  }
  &__icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    //animation: pulse 6.5s infinite;
    &_phone {
      position: absolute;
      right: 0;
      background: #00271D;
      border-radius: 50%;
      padding: 20px;
    }
    &_content {
      display: flex;
      align-items: center;
      background-color: $quaternary;
      position: absolute;
      right: 0;
      border-radius: 40px;
      padding: 20px 90px 20px 30px;
      gap: 30px;
      @media screen and (max-width: $tablet) {
        right: 0;
        gap: 15px;
        padding: 20px 65px 20px 25px;
      }
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          text-decoration: underline;
          font-size: min(max(10px, calc(0.625rem + ((1vw - 4.24px) * 0.1873))), 14px);
        }
        @media screen and (max-width: $mobile) {
          gap: 5px;
        }
      }
    }
  }
}
.slide-in {
  animation: slideInAnimation 0.7s ease-out;
}
@keyframes slideInAnimation {
  0% {
    opacity: 0;
    transform: translateX(2%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>