<template>
  <div class="home__services-content">
    <div class="home__services-content-top container">
      <h1 class="bold-txt title">{{ $t('services.title') }}</h1>
      <div class="home__services-content-top-tabs">
        <div
            class="home__services-content-top-tabs-item"
            v-for="(item, index) in servicesTab"
            @click="selectTab(index)"
            :class="{tabActive: actualTab === index}"
            :key="index"
        >
          <p class="bold-txt">{{ $t(item.category) }}</p>
        </div>
      </div>
    </div>
    <div class="swiper-container" v-if="actualTab !== null">
      <div class="swiper-wrapper">
        <div
            class="swiper-slide"
            v-for="(service, serviceIndex) in actualTabData"
            :key="serviceIndex"
        >
          <div class="service-image">
            <img :src="require(`@/assets/img/png/${service.image}`)" alt="service-image" class="service-image" />
          </div>
          <div class="service-info">
            <h1 class="service-info-title bold-txt title">{{ $t(service.title) }}</h1>
            <p class="service-info-desc light-txt">{{ $t(service.description) }}</p>
          </div>
        </div>
      </div>
      <div class="swiper-button-prev" @click="prevCategory"></div>
      <div class="swiper-button-next" @click="nextCategory"></div>
    </div>
  </div>
</template>

<script>
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
export default {
  data: () => ({
    actualTab: null,
    actualTabData: [],
    servicesTab: [
      {
        category: "services.category1",
        services: [
          {
            image: "services1.png",
            title: "services.category1",
            description: "services.desc1"
          },
        ]
      },
      {
        category: "services.category2",
        services: [
          {
            image: "services1.png",
            title: "services.category2",
            description: "services.desc2"
          },
        ]
      },
      {
        category: "services.category3",
        services: [
          {
            image: "services1.png",
            title: "services.category3",
            description: "services.desc3"
          },
        ]
      },
      {
        category: "services.category4",
        services: [
          {
            image: "services1.png",
            title: "services.category4",
            description: "services.desc4"
          },
        ]
      },
    ],
    swiper: null,
  }),
  methods: {
    selectTab(index) {
      this.actualTab = index;
      this.actualTabData = this.servicesTab[index].services;
    },
    prevCategory() {
      if (this.actualTab > 0) {
        this.selectTab(this.actualTab - 1);
      } else {
        this.selectTab(this.servicesTab.length - 1);
      }
    },
    nextCategory() {
      if (this.actualTab < this.servicesTab.length - 1) {
        this.selectTab(this.actualTab + 1);
      } else {
        this.selectTab(0);
      }
    },
  },
  mounted() {
    this.selectTab(0);
  },

}
</script>

<style lang="scss" scoped>
.home__services-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 80px;
  gap: 50px;
  @media screen and (max-width: $tablet) {
    padding-top: 0;
  }
  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    @media screen and (max-width: $tablet) {
      gap: 36px;
    }
    &-tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #C4C6C7;
      overflow-x: auto;
      width: 100%;
      gap: 40px;
      @media screen and (max-width: $tablet) {
        gap: 35px;
        justify-content: flex-start;
      }
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      &-item {
        color: #7C7D7E;
        cursor: pointer;
        width: 100%;
        p {
          font-size: 16px;
          width: max-content;
          padding-bottom: 16px;
        }
      }
    }
  }

  .swiper-container {
    width: 95%;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: $tablet) {
      width: 100%;
    }
    .swiper-wrapper {
      transition-property: transform;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      gap: 115px;
      background-color: $white;
      width: 100%;
      max-height: 480px;
      @media screen and (max-width: 1200px) {
        gap: 55px;
      }
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        background-color: transparent;
        max-height: 100%;
        gap: 25px;
      }
      .service-image {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        width: 100%;
        height: 480px;
        @media screen and (max-width: $tablet) {
          height: 308px;
        }
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      .service-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-right: 110px;
        gap: 16px;
        width: 80%;
        height: 100%;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
        @media screen and (max-width: $tablet) {
          width: 100%;
          padding: 0 40px;
          text-align: center;
          align-items: center;
        }
        .service-info-title {
          width: 70%;
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
        }
        .service-info-desc {
          width: 100%;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 4.24px) * 0.0936))), 14px);
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      width: 88px;
      height: 88px;
      background-color: #E4E4E4;
      cursor: pointer;
      z-index: 10;
      @media screen and (max-width: $tablet) {
        width: 44px;
        height: 44px;
        top: 308px;
      }
      &::after {
        color: #7C7D7E;
        font-size: 20px;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }
  }
}
.tabActive {
  border-bottom: 3px solid #419468;
  color: $black;
}
</style>