<template>
  <div class="home">
    <div class="home__main" ref="main">
      <div class="home__main_content container">
        <div class="home__main_content-title title">
          <h1 class="bold-txt">{{ $t('homeTitle') }}</h1>
        </div>
        <div
            class="home__main_content-cookies"
            v-if="!hasAcceptedCookies"
        >
          <div class="home__main_content-cookies-toaster">
            <p class="home__main_content-cookies-toaster-txt" v-html="$t('cookiesInfo')"></p>
            <button
                class="home__main_content-cookies-toaster-btn"
                @click="acceptCookies"
            >
              <span class="bold-txt">{{ $t('buttons.accept') }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="home__main_gradient"></div>
    </div>

    <div class="home__advantages container">
      <HomeAdvantages/>
    </div>

    <span class="line container"></span>

    <div class="home__services">
      <HomeServices/>
    </div>

    <div class="home__submitApp">
      <HomeSubmitApp/>
    </div>

    <div class="home__map container">
      <HomeMap/>
    </div>
  </div>
</template>

<script>
import HomeAdvantages from "@/components/home/HomeAdvantages.vue";
import HomeServices from "@/components/home/HomeServices.vue";
import HomeSubmitApp from "@/components/home/HomeSubmitApp.vue";
import HomeMap from "@/components/home/HomeMap.vue";
import Cookies from 'js-cookie';

export default {
  components: {
    HomeMap,
    HomeSubmitApp,
    HomeServices,
    HomeAdvantages
  },
  data() {
    return {
      hasAcceptedCookies: false,
    };
  },
  methods: {
    acceptCookies() {
      Cookies.set('cookieConsent', 'true', { expires: 365 });
      // При клике на кнопку принятия cookies сохраняем информацию о принятии в локальное хранилище
      localStorage.setItem('hasAcceptedCookies', true);
      // Обновляем значение в data
      this.hasAcceptedCookies = true;
    },
    setMainHeight() {
      const main = this.$refs.main;
      main.style.height = window.innerHeight * 0.93 + "px";
    },
  },
  mounted() {
    // Проверяем, были ли cookies приняты ранее
    const storedValue = localStorage.getItem('hasAcceptedCookies');
    if (storedValue) {
      // Если да, скрываем блок с cookies
      this.hasAcceptedCookies = true;
    }
    this.setMainHeight();
    // Обновляем высоту блока "home__main" при изменении размера окна
    window.addEventListener("resize", this.setMainHeight);
  },
  beforeUnmount() {
    // Удаляем слушатель события при уничтожении компонента
    window.removeEventListener("resize", this.setMainHeight);
  }
}
</script>

<style scoped lang="scss">
.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__main {
    background-image: url("@/assets/img/png/home-banner.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    color: $white;
    &_gradient {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50%;
      z-index: 0;
      background: linear-gradient(180deg, #262626 -6.77%, rgba(38, 38, 38, 0) 94.22%);
      transform: rotate(180deg);
    }
    &_content {
      display: flex;
      align-items: flex-end;
      height: 100%;
      width: 100%;
      position: relative;
      &-title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;
        width: 20%;
        z-index: 3;
        @media screen and (max-width: 1200px) {
          margin-bottom: 150px;
        }
        @media screen and (max-width: $tablet) {
          margin-bottom: 180px;
        }
      }
      &-cookies {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        color: $quaternary;
        &-toaster {
          display: flex;
          align-items: center;
          max-width: 650px;
          background-color: $white;
          @media screen and (max-width: $tablet) {
            max-width: 320px;
          }
          &-txt {
            padding: 30px;
            font-size: min(max(10px, calc(0.625rem + ((1vw - 4.24px) * 0.1873))), 14px);
            @media screen and (max-width: $tablet) {
              padding: 20px 15px;
            }
          }
          &-btn {
            padding: 35px 30px;
            background-color: $quinary;
            width: 235px;
            height: 100%;
            cursor: pointer;
            text-align: center;
            span {
              font-size: min(max(14px, calc(0.875rem + ((1vw - 4.24px) * 0.1873))), 18px);
            }
            &:hover {
              background-color: $grey;
            }
          }
        }
      }
    }
  }

  &__advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 80px 0;
    @media screen and (max-width: $tablet) {
      padding: 40px 0;
    }
  }

  &__submitApp {
    display: flex;
    flex-direction: column;
    background-color: $quaternary;
    margin: 80px 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: $tablet) {
      margin-top: 80px;
      margin-bottom: 0;
    }
  }
  &__map {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    @media screen and (max-width: $tablet) {
      max-width: 100%;
    }
    @media screen and (max-width: $tablet) {
      margin-bottom: 0px;
      width: 100%;
    }
  }
}

.line {
  width: 100%;
  border: 1px solid #C4C6C7;
  @media screen and (max-width: $tablet) {
    display: none;
  }
}
</style>
