<template>
  <div class="home__advantages-info">
    <h1 class="home__advantages-info-title bold-txt title">{{ $t('advantages.title') }}</h1>
    <div class="home__advantages-info-cards">
      <div
          class="home__advantages-info-cards-item cards"
          v-for="(info, index) in advantageInfo"
          :key="index"
      >
        <picture>
          <img :src="require(`@/assets/img/png/${info.src}`)" :alt="info.alt">
        </picture>
        <div class="home__advantages-info-cards-item-desc">
          <p>{{ $t(info.desc) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      advantageInfo: [
        { src: 'advantage-1.png', alt: 'advantage-1', desc: 'advantages.desc1'},
        { src: 'advantage-2.png', alt: 'advantage-2', desc: 'advantages.desc2'},
        { src: 'advantage-3.png', alt: 'advantage-3', desc: 'advantages.desc3'},
        { src: 'advantage-4.png', alt: 'advantage-4', desc: 'advantages.desc4'}
      ]
    };
  },
}
</script>

<style lang="scss" scoped>
.home__advantages-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    text-align: center;
    padding-bottom: 80px;
    @media screen and (max-width: $tablet) {
      padding-bottom: 40px;
    }
  }
  &-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      gap: 15px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 85px 30px 35px 30px;
      width: 290px;
      height: 290px;
      gap: 57px;
      @media screen and (max-width: 1200px) {
        padding: 85px 30px 45px 30px;
      }
      @media screen and (max-width: $tablet) {
        width: 310px;
        height: 252px;
        gap: 40px;
        padding: 50px 40px;
      }
      &-desc {
        display: flex;
        align-items: center;
        text-align: center;
        height: 55px;
        @media screen and (max-width: 1200px) {
          height: 105px;
        }
        p {
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
</style>