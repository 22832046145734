<template>
  <div class="home__map-contacts">
    <div class="home__map-contacts-left">
      <a href="https://go.2gis.com/j7kdt" class="home__map-contacts-left-address">
        <img src="@/assets/img/svg/map-point.svg" alt="map-point">
        <span class="bold-txt">{{ $t('map.address') }}</span>
      </a>
      <div class="home__map-contacts-left-info">
        <p class="bold-txt">{{ $t('map.sales') }}</p>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-phone.svg" alt="map-phone">
          <p class="regular-txt">5588</p>
        </div>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-watch.svg" alt="map-watch">
          <span class="regular-txt">{{ $t('map.weekday') }}</span>
        </div>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-watch.svg" alt="map-watch">
          <span class="regular-txt">{{ $t('map.weekend') }}</span>
        </div>
      </div>

      <div class="home__map-contacts-left-info">
        <p class="bold-txt">{{ $t('map.service') }}</p>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-phone.svg" alt="map-phone">
          <span class="regular-txt">9898</span>
        </div>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-watch.svg" alt="map-watch">
          <span class="regular-txt">{{ $t('map.weekday') }}</span>
        </div>
        <div class="home__map-contacts-left-info-txt">
          <img src="@/assets/img/svg/map-watch.svg" alt="map-watch">
          <span class="regular-txt">{{ $t('map.weekend') }}</span>
        </div>
      </div>
    </div>
    <div class="home__map-contacts-right">
      <div class="home__map-contacts-right-map" style="position: relative;">
        <div
            class="home__map-contacts-right-map-view"
            id="dilers-map-container"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: null,
      geocoder: null,
      marker: null,
    };
  },
  mounted() {
    this.load2GISAPI().then(() => {
      this.initMap();
    });
  },
  methods: {
    load2GISAPI() {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://maps.api.2gis.ru/2.0/loader.js?pkg=full';
        script.onload = resolve;
        document.head.appendChild(script);
      });
    },
    initMap() {
      const mapContainer = document.getElementById('dilers-map-container');
      // Инициализация карты 2GIS
      DG.then(() => {
        this.map = DG.map(mapContainer, {
          center: [43.323561, 76.975433],
          zoom: 16,
        });
        DG.marker([43.323561, 76.975433]).addTo(this.map);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.home__map-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  width: 100%;
  height: 510px;
  @media screen and (max-width: $tablet) {
    display: grid;
  }
  &-left {
    display: flex;
    flex-direction: column;
    padding: 90px 150px 105px 150px;
    gap: 20px;
    @media screen and (max-width: 1200px) {
      padding: 45px 95px;
    }
    @media screen and (max-width: $tablet) {
      padding: 30px 55px;
    }
    &-address {
      display: flex;
      align-items: center;
      padding: 15px 0;
      gap: 7px;
      &:hover {
        text-decoration: underline;
      }
      @media screen and (max-width: $tablet) {
        width: 100%;
        justify-content: center;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      gap: 15px;
      @media screen and (max-width: $tablet) {
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      &-txt {
        display: flex;
        align-items: center;
        gap: 7px;
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    &-map{
      width: 697px;
      height: 100%;
      @media screen and (max-width: 1200px) {
        width: 500px;
      }
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
      &-view {
        height: 510px;
        width: 100%;
        @media screen and (max-width: $tablet) {
          height: 280px;
        }
      }
    }
  }
}
@media screen and (max-width: $tablet) {
  .home__map-contacts {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: 100%;
  }

  .home__map-contacts-left,
  .home__map-contacts-right {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 0;
  }

  .home__map-contacts-right {
    order: -1;
  }
}
</style>