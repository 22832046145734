import { createStore } from 'vuex'
// import router from "@/router";
import {createToaster} from "@meforma/vue-toaster";
// import i18n from "@/i18n";
const toaster = createToaster({ position: "top-right", duration: 1900 });
export default createStore({
    state: {
        lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kz' : 'ru' || 'ru',
    },
    getters: {
        getLang: (state) => state.lang,
    },
    mutations: {
        UPDATE_LANG(state, lang) {
            state.lang = lang
            localStorage.setItem("lang", lang);
        },
    },
    actions: {
    },
    modules: {
    }
})
