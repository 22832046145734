<template>
  <header class="header">
    <nav class="header__nav container">
      <div class="header__nav-left">
        <picture>
          <img src="@/assets/img/png/logo-skoda.png" alt="logo-skoda">
        </picture>
      </div>
      <div class="header__nav-right">
        <a href="tel:9898" class="header__nav-right-contact">
          <img src="@/assets/img/svg/phone-icon.svg" alt="phone">
          <p class="bold-txt">9898</p>
        </a>
        <span class="header__nav-right-line"></span>
        <div class="header__nav-right-lang">
          <p
              class="header__nav-right-lang-item"
              @click="switchLanguage('ru')"
              :class="{ 'bold-txt': $i18n.locale === 'ru' }"
          >
            RU
          </p>
          <p
              class="header__nav-right-lang-item"
              @click="switchLanguage('kz')"
              :class="{ 'bold-txt': $i18n.locale === 'kz' }"
          >
            KZ
          </p>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name:'HeaderVue',
  data() {
    return {
    };
  },
  methods: {
    switchLanguage(lang) {
      this.$store.commit('UPDATE_LANG', lang);
      this.$i18n.locale = lang;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: column;
  background-color: $quaternary;
  height: 60px;
  width: 100%;
  color: $white;
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    &-left {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 24px;
      &-line {
        border: 1px solid #7C7D7E;
        height: 27px;
      }
      &-contact {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      &-lang {
        display: flex;
        align-items: center;
        gap: 15px;
        &-item {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>