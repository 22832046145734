<template>
  <div class="home__submitApp_form">
    <div class="home__submitApp_form-content">
      <h1 class="home__submitApp_form-content-title regular-txt">{{ $t('submitApp.title') }}</h1>
      <p class="home__submitApp_form-content-desc">
        {{ $t('submitApp.desc') }}
      </p>
      <form class="home__submitApp_form-content-app">
        <div class="home__submitApp_form-content-app-input">
          <span>{{ $t('submitApp.name') }}</span>
          <input
              type="text"
              v-model="v$.name.$model"
          >
          <template v-for="(error, index) of v$.name.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="home__submitApp_form-content-app-input">
          <span>{{ $t('submitApp.surname') }}</span>
          <input
              type="text"
              v-model="v$.surname.$model"
          >
          <template v-for="(error, index) of v$.surname.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="home__submitApp_form-content-app-input">
          <span>{{ $t('submitApp.phone') }}</span>
          <input
              type="text"
              placeholder="+7 (___) ___ __ __"
              v-model="v$.phone.$model"
              v-imask="phoneMask"
              @input="isNumber"
              @accept="onAccept"
              @complete="onComplete"
          >
          <template v-for="(error, index) of v$.phone.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="home__submitApp_form-content-app-input">
          <span>{{ $t('submitApp.comment') }}</span>
          <textarea :placeholder="$t('text')" v-model="comments"/>
        </div>
        <div class="home__submitApp_form-content-app-btn">
          <p class="home__submitApp_form-content-app-btn-txt">
            {{ $t('submitApp.policy') }}
          </p>
          <button
              class="home__submitApp_form-content-app-btn-button"
              @click.prevent="sendForm"
          >
            <span class="bold-txt">{{ $t('buttons.send') }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <ModalWindow :is-open="isOpen" @close="isOpen = false">
    <div class="submitSuccess">
      <h2 class="submitSuccess-title regular-txt">Благодарим Вас за обращение!</h2>
      <p class="submitSuccess-txt">Дилер SKODA свяжется с Вами в ближайшее время!</p>
      <button
          class="submitSuccess-button" @click="isOpen = false"
      >
        <span class="bold-txt">Подтвердить</span>
      </button>
    </div>
  </ModalWindow>
</template>

<script>
import { IMaskDirective } from "vue-imask";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import axios from "axios";
import ModalWindow from "@/components/ModalWindow.vue";
export default {
  components: {
    ModalWindow
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      v$: useVuelidate(),
      name: '',
      surname: '',
      comments: '',
      phone: '',
      send: false,
      isOpen: false,
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 3}),
            minLength(3)
        ),
      },
      surname: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 3}),
            minLength(3)
        ),
      },
      phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phoneFormat')), minLength(18)
        ),
      },
    };
  },
  methods: {
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error(this.$t('toasters.phone'));
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
      console.log("complete", maskRef.unmaskedValue);
    },
    async sendForm() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        try {
          const res = await axios.post("https://skoda.allur.kz/rest/1/rrj7jbal1s58vgf5/crm.contact.add.json", {
            FIELDS: {
              NAME: this.name,
              LAST_NAME: this.surname,
              SECOND_NAME: "",
              PHONE: [{
                VALUE: this.phone,
                VALUE_TYPE: "WORK"
              }],
              EMAIL: ""
            }
          });
          if (res.status === 200) {
            const contactData1 = {
              FIELDS: {
                UF_CRM_1686750597: 22,
                CATEGORY_ID: 7,
                STAGE_ID: "C7:NEW",
                UF_CRM_1686845874: 777,
                SOURCE_ID: "WEBFORM",
                UF_CRM_1686843703: 734,
                UF_CRM_1634038284: "skoda.com.kz",
                UF_CRM_1686842116: 725,
                UF_CRM_1686814065: 0,
                CONTACT_ID: res.data.result,
                COMMENTS: this.comments,
              },
            };
            axios
                .post(
                    "https://skoda.allur.kz/rest/1/rrj7jbal1s58vgf5/crm.deal.add.json",
                    contactData1
                )
                .then((response) => {
                  if (response.status === 200) {
                    this.isOpen = true;
                    this.resetForm();
                  } else {
                    console.log(response);
                  }
                })
                .catch((e) => {
                  console.log(e);
                })
            }
          } catch (err) {
          if (err.response && err.response.status === 500) {
            this.$toaster.error(err.response.data.data.message);
          } else if (err.response && err.response.data.error) {
            this.$toaster.error(err.response.data.error.message);
          }
        }
      } else {
        this.$toaster.error(this.$t('toasters.fullInput'));
      }
    },
    resetForm() {
      this.name = "",
      this.surname = "",
      this.phone = "",
      this.comments = "",
      this.v$.$reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.home__submitApp_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: $white;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 370px;
    padding: 90px 0;
    gap: 15px;
    @media screen and (max-width: $tablet) {
      padding: 55px 0;
    }
    &-title {
      text-align: center;
      font-size: min(max(20px, calc(1.25rem + ((1vw - 4.24px) * 0.3745))), 28px);
    }
    &-desc {
      text-align: center;
      font-size: 14px;
    }
    &-app {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      &-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $white;
        color: $black;
        width: 100%;
        span {
          padding: 8px 10px;
          font-size: 9px;
        }
        input {
          width: 100%;
          padding: 8px 10px;
          font-family: $skodaFontBold;
          font-size: 14px;
          &:focus {
            outline: none;
          }
        }
        textarea {
          width: 100%;
          height: 100px;
          padding: 8px 10px;
          font-family: $skodaFontRegular;
          &:focus {
            outline: none;
          }
        }
      }
      &-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        &-txt {
          font-size: 14px;
          text-decoration: underline;
          text-align: center;
        }
        button {
          padding: 15px 40px;
          border-radius: 149px;
          background-color: $quinary;
          color: $quaternary;
        }
      }
    }
  }
}
.errorValid {
  color: #f72a2a;
  font-size: 14px;
  padding: 8px 10px;
}
.submitSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 110px 70px 110px;
  gap: 40px;
  @media screen and (max-width: $tablet) {
    padding: 50px 55px 35px 55px;
  }
  &-title {
    font-size: min(max(20px, calc(1.25rem + ((1vw - 4.24px) * 0.3745))), 28px);
    color: #232425;
  }
  &-txt {
    font-size: 14px;
    color: #232425;
  }
  &-button {
    padding: 15px 40px;
    border-radius: 149px;
    background-color: $quinary;
    color: $quaternary;
  }
}
</style>