<template>
  <div class="main">
    <Header />
      <div class="main-flex">
        <router-view />
      </div>
    <Footer />
  </div>
</template>
<script>
import Header from './components/layouts/Header.vue';
import Footer from './components/layouts/Footer.vue';
export default {
  name:'App',
  components:{
    Header,
    Footer
  },
}
</script>

<style lang="scss">
.main {
  background-color: #f3f3f3;
}
</style>
